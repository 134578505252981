import React, { useState, useEffect } from 'react';
import './App.css';

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://ferl.fyi/api'
  : 'http://localhost:3001';

function App() {
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    company_name: '',
    min_rating: '',
    min_reviews: '',
    sort_by: 'overall_rating',
    sort_order: 'DESC'
  });

  const fetchCompanies = async () => {
    const queryParams = new URLSearchParams(filters);
    try {
      console.log('Fetching companies from:', `${API_URL}/companies?${queryParams}`);
      const response = await fetch(`${API_URL}/companies?${queryParams}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Received data:', data);
      setCompanies(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Company Metrics Dashboard</h1>
      </header>
      
      <div className="filters">
        <input
          type="text"
          name="company_name"
          placeholder="Filter by company name"
          value={filters.company_name}
          onChange={handleFilterChange}
        />
        <input
          type="number"
          name="min_rating"
          placeholder="Min rating"
          value={filters.min_rating}
          onChange={handleFilterChange}
        />
        <input
          type="number"
          name="min_reviews"
          placeholder="Min reviews"
          value={filters.min_reviews}
          onChange={handleFilterChange}
        />
        <select
          name="sort_by"
          value={filters.sort_by}
          onChange={handleFilterChange}
        >
          <option value="overall_rating">Overall Rating</option>
          <option value="number_of_reviews">Number of Reviews</option>
          <option value="career_growth">Career Growth</option>
          <option value="work_life_balance">Work Life Balance</option>
          <option value="compensation_benefits">Compensation/Benefits</option>
          <option value="company_culture">Company Culture</option>
          <option value="management">Management</option>
        </select>
        <select
          name="sort_order"
          value={filters.sort_order}
          onChange={handleFilterChange}
        >
          <option value="DESC">Highest First</option>
          <option value="ASC">Lowest First</option>
        </select>
      </div>

      <div className="companies-table">
        <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Overall Rating</th>
              <th>Reviews</th>
              <th>Career Growth</th>
              <th>Work Life Balance</th>
              <th>Compensation</th>
              <th>Culture</th>
              <th>Management</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => (
              <tr key={index}>
                <td>{company.company_name}</td>
                <td>{company.overall_rating}</td>
                <td>{company.number_of_reviews}</td>
                <td>{company.career_growth}</td>
                <td>{company.work_life_balance}</td>
                <td>{company.compensation_benefits}</td>
                <td>{company.company_culture}</td>
                <td>{company.management}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
